<template>
<div class="main">
  <div class="app-wrapper">
    <div class="machine_search">
      <div class="machine_search_btn" @click="addWorkshop()">
        <div class="search_btn">{{$t('Machine.Add')}}</div>
      </div>
      <div class="machine_search_space">
      </div>
    </div>
  <div class="table_content">
    <el-table
    ref="multipleTable"
    :data="tableData"
    tooltip-effect="dark"
    :header-cell-style="{background:'#dde0e8'}"
    highlight-current-row
    style="width:100%"
  >

    <!-- ele 暂无数据插槽 -->
    <!-- <template slot="empty">
      <img src="@/assets/image/emptyData.png" v-show='noDataTable'/>
      <div class="empty_text" v-show='noDataTable'>{{ $t('Basic.NoData')}}</div>
    </template> -->
    <el-table-column width="50" align="center" />
    <el-table-column min-width="20%" :label="$t('Machine.Workshop')"  show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.name }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="20%"  :label="$t('Setting.CreationTime')"  show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.createtime | time }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="20%"  :label="$t('Setting.ModificationTime')"  show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.updatetime | time }}</div>
      </template>
    </el-table-column>
          <el-table-column :label="$t('AddMachine.Operation')"  width="120" align="left">
            <template slot-scope="scope">
              <img src="../../../assets/image/bianji_main@2x.png" @click="handleEditOrder(scope.row)" class="OperationImg" >
              <img src="../../../assets/image/shanchu_mian@2x.png" @click="handleDeleteOrder(scope.row)" class="OperationImg">
            </template>
          </el-table-column>
  </el-table>
  </div>
  <div class="pagination-container">
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-size="pagesize"
      :page-sizes="[5,10,15]"
      :current-page.sync="page"
      :total="total"
      v-show="total&&total>0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  </div>
        <el-dialog
        :title="type=='edit'?$t('Setting.EditWorkshop'):$t('Setting.AddWorkshop')"
        :visible.sync="dialogVisible"
        width="364px"
        class="dialog"
        >
        <el-form ref="workShopForm" :model="workShop" label-width="0px">
            <div class="form-box">
            <el-form-item prop="name">
                <div class="label" style="text-align:left">{{$t('Machine.Workshop')}}</div>
                <el-input v-model.trim="workShop.name" :placeholder="$t('Setting.WorkshopInput')" auto-complete="off" clearable  @input="changeIpt($event)"/>
            </el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">

            <div class="btn-normal pan-btn blue-btn" style="margin-right:0px;background:#4875ef" @click="handleConfirm(workShop)" :style="{background:isBlue?'#3660DD':'#4875ef'}">{{$t('Login.OK')}}</div>
            <div class="btn-normal pan-btn tiffany-btn" @click="dialogVisible = false" style='background:#767e95' >{{$t('AddMachine.Cancel')}}</div>
        </span>
        </el-dialog>
    </div>
</template>

<script>
// import { getErrorMoldList } from '../../api/warning'
import { getWorkshop, deleteWorkshop, addWorkshop, editWorkshop } from '@/api/machine'
export default {
  name: 'Workshop',
  data () {
    return {
      Startime: '',
      dialogVisible: false,
      type: '',
      repeat: true,
      isBlue: false,
      workShop: {
        name: ''
      },
      Endtime: '',
      tableData: [],
      total: 0,
      page: 1,
      pagesize: 15
    }
  },
  created () {
    this.getWorkshop()
  },
  filters: {
    time (item) {
      const date = new Date(item * 1000)
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
      const hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
      const minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
      return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute
    }
  },
  watch: {
    '$i18n.locale' (val) {
      this.changeLan()
    }

  },
  methods: {
    changeLan () {
      if (this.tableData.length > 0) {
        if (this.tableData[0].name === '默认车间' || this.tableData[0].name === 'Default Workshop') {
          this.$set(this.tableData[0], 'name', this.$t('Tip.Default'))
        }
      }
    },
    changeIpt (e) {
      this.$forceUpdate()
    },
    handleEditOrder (val) {
      this.type = 'edit'
      this.dialogVisible = true
      this.workShop.name = val.name
      this.workShop.id = val.id
    },
    handleDeleteOrder (val) {
      this.$confirm(this.$t('Tip.DeleteWorkshop'), this.$t('Tip.Tips'), {
        confirmButtonText: this.$t('Login.OK'),
        cancelButtonText: this.$t('AddMachine.Cancel')
      }).then(() => {
        const params = {}
        params.id = val.id
        const token = localStorage.getItem('token')
        const lang = localStorage.getItem('language')
        deleteWorkshop(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t('Setting.RemovedSuccessfully'),
              type: 'success',
              duration: 1000
            })
          }
          this.getWorkshop()
        })
      }).catch(() => {
      })
    },
    addWorkshop () {
      this.workShop = {}
      this.type = 'add'
      this.dialogVisible = true
    },
    handleConfirm (workShop) {
      this.isBlue = true
      setTimeout(() => {
        this.isBlue = false
      }, 500)
      const params = {}
      if (!this.repeat) {
        return
      }
      this.repeat = false
      setTimeout(() => {
        this.repeat = true
      }, 2000)
      if (!workShop.name) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.WorkShopIp'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (workShop.name.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t('Tip.WorkshopExceed'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.type === 'add') {
        params.name = workShop.name
        const token = localStorage.getItem('token')
        const lang = localStorage.getItem('language')
        addWorkshop(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.dialogVisible = false
            this.$message({
              showClose: true,
              message: this.$t('Tip.AddSuccess'),
              type: 'success',
              duration: 1000
            })
          }

          this.getWorkshop()
        })
      } else {
        params.id = workShop.id
        params.name = workShop.name
        const token = localStorage.getItem('token')
        const lang = localStorage.getItem('language')
        editWorkshop(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.dialogVisible = false
            this.$message({
              showClose: true,
              message: this.$t('Tip.EditSuccess'),
              type: 'success',
              duration: 1000
            })
          }
          this.getWorkshop()
        })
      }
    },
    getWorkshop () {
      const params = {}
      params.page = this.page
      params.pagesize = this.pagesize
      params.token = localStorage.getItem('token')
      params.language = localStorage.getItem('language')

      getWorkshop(params).then((res) => {
        this.tableData = res.data.list
        this.total = res.data.count
        this.changeLan()
      })
    },
    handleSizeChange (val) {
      this.page = 1
      this.pagesize = val
      this.getWorkshop()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getWorkshop()
    }
  }
}
</script>

<style lang="less" scoped>
@media (max-width:1367px) {
  .dialog /deep/ .el-input__suffix {
      right: 20px !important;
  }
}
@media (min-width:1367px) and (max-width:1441px) {
  .dialog /deep/ .el-input__suffix {
      right: 20px !important;
  }
}
.pagination-container{
  margin:20px 25px;
  float: left;
}
.dialog /deep/ .el-dialog{
    border-radius: 20px !important;
    background: #e7e9ee;
    margin-top: 30vh !important;
    filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialog /deep/ .el-dialog__body {
    padding: 0px 20px 10px 20px;
}
.dialog /deep/ .el-input__suffix {
    right: 20px;
}
.btn-normal {
    width: 70px;
    height: 32px;
    border-radius: 20px;
    font-size: 13px;
    line-height: 34px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    float: right;
    margin-left: 10px;
    cursor: pointer;
}
.dialog /deep/ .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
}
.table_content /deep/ .el-table .cell {
    padding-left: 0px !important;
}
.dialog /deep/ .plan-form.r3 .label {
    font-size: 16px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}
.dialog /deep/ .plan-form .row .form-item{
    width: 33.4% !important;
}
.dialog /deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 20px;
}
.dialog /deep/ .el-dialog__body{
  padding: 20px 20px;
}
.dialog /deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    text-align: left;
}
.dialog /deep/ .el-form-item__content {
    line-height: 35px;
    font-size: 18px;
  }
.dialog /deep/ .el-dialog__headerbtn{
  font-size: 30px !important;
  top: 12px;
}
.dialog /deep/ .el-dialog__footer {
    padding: 0px 20px 15px !important;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}
.main{
  width: 100%;
  height: 100%;
  background-color: #ebecee;

.app-wrapper{
  background-color: #ebecee !important;
  height: 100%;
  .table_content{
    height:calc(100vh - 270px);
    overflow: auto;
    /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #ebecee;
    }
    /deep/ .el-table tr {
      // background-color: #ebecee !important;
      cursor: pointer;
    }
  }
  .machine_title{
    padding: 22px 20px;
    color: #414141;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
  }
  .machine_search{
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 25px;
    .machine_search_list{
      flex: 1.55;
      .search_title{
        text-align: left;
        color:#4d4f5c;
        font-size: 18px;
      }
      .search_selected{
        float: left;
        margin: 10px 10px 10px 0;

        /deep/ .el-date-editor.el-input,/deep/ .el-date-editor.el-input__inner {
            width: 100%;
        }
        /deep/ .el-input__inner{
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input__icon {
          margin-top: 5px;
          color:#a4afb7;
          font-weight:bold;
        }
        /deep/ .input-wrapper .el-input__icon{
          margin-top: 2px;
        }
        /deep/ .el-select .el-input .el-select__caret.is-reverse {
          margin-top: -3px;
        }
      }
    }
    .machine_search_btn{
      .search_btn{
        width: 70px;
        height: 32px;
        background: #4875ef;
        font-size: 13px;
        color:#f2f3f6;
        text-align: center;
        line-height: 32px;
        border-radius:30px;
        margin:20px;
        cursor: pointer;
      }
      .edi_color{
        background-color: #767e95;
      }
    }
    .machine_search_space{
      flex: 5.4;
    }
  }
}
}

</style>
