<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_search">
        <el-row style="width: 100%">
          <el-col :span="5">
            <div class="machine_search_list">
              <div class="search_title">{{ $t("Machine.Workshop") }}</div>
              <div class="search_selected">
                <el-select v-model="workshop" @change="changeWorkshop">
                  <el-option :label="$t('Machine.All')" value="0" />
                  <el-option
                    :label="item.name"
                    v-model="item.id"
                    v-for="item in workshopList"
                    :key="item.id"
                  />
                </el-select>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="machine_search_list">
              <div class="search_title">{{ $t("Machine.Group") }}</div>
              <div class="search_selected">
                <el-select v-model="group" @change="changeGroup">
                  <el-option
                    :label="$t('Machine.All')"
                    value="0"
                    v-show="workshop === '0'"
                  />
                  <el-option
                    :label="item.name"
                    v-model="item.id"
                    v-for="item in groupList"
                    :key="item.id"
                  />
                </el-select>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="machine_search_list">
              <div class="search_title">{{ $t("Machine.Equipment") }}</div>
              <div
                class="search_selected"
                style="position: relative; width: 206px"
              >
                <!-- <input type="text" :placeholder="$t('Machine.EquipmentName')"  class="Manual_search_left" v-model="machine"  @blur="blueIpt" @focus="focusIpt"/>
            <img src='../../../assets/image/clearable.png' class="clear" @mousedown.stop='cleareMachine' v-show="machine&&isClear" :style="{right:$i18n.locale==='zh'?'12px':'12px'}"/>
            <div  class="spm_size" v-show="machineArray.length>0" :style="{left:$i18n.locale==='zh'?'35px':'35px'}"></div>
            <div class="search_dia" v-show="machineArray.length>0">

                <div class="search_dia_list" v-for="item of machineArray" :key="item.id"  >
                    <div class="title-info" @click="selectName(item.name)">{{item.name}}</div>
                </div>
            </div> -->
                <el-autocomplete
                  class=""
                  v-model="machine"
                  :fetch-suggestions="querySearch"
                  :placeholder="$t('Machine.EquipmentName')"
                  style="width: 100%"
                  @blur="blueIpt"
                  @focus="focusIpt"
                ></el-autocomplete>
                <img
                  src="../../../assets/image/clearable.png"
                  class="clear"
                  @mousedown.stop="cleareMachine"
                  v-show="machine && isClear"
                />
              </div>

              <!-- <div class="search_selected">
          <el-select v-model="machine" filterable remote clearable :placeholder="$t('AddMachine.selectEquipment')" size="mini" :remote-method="getMachineName" class='seriese'>
            <el-option v-for="item in machineArray" :key="item.id" :label="item.name" :value="item.name" />
          </el-select>
        </div> -->
            </div>
          </el-col>
          <el-col :span="language === 'zh_CN' ? 5 : 6">
            <div class="machine_search_list">
              <div class="search_title">
                {{ $t("AddMachine.EquipmentNumber") }}
              </div>
              <div class="search_selected">
                <el-input
                  :label="$t('Machine.Serial')"
                  v-model="serial_number"
                  :placeholder="$t('AddMachine.EquipmentNumberInput')"
                  clearable
                  class="seriese"
                />
              </div>
            </div>
          </el-col>
          <el-col :span="1">
            <div class="machine_search_list searchList" style="flex: 0.2">
              <div class="machine_search_btn">
                <div
                  class="search_btn"
                  @click="searchMachine"
                  :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }"
                >
                  {{ $t("Machine.Search") }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- <div class="machine_search_space"></div> -->
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          @row-click="rowClick"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          style="width: 100%"
          row-key="id"
          :expand-row-keys="expandRowKeys"
          @expand-change="expandChange"
        >
          <el-table-column width="10" align="center" />
          <el-table-column type="expand" min-width="10%">
            <template slot-scope="props">
              <el-table
                ref="multipleTable"
                :data="props.row.devices"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ background: '#dde0e8' }"
              >
                <el-table-column width="70" align="center" />
                <el-table-column
                  :label="$t('AddMachine.EquipmentName')"
                  prop="name"
                  min-width="22%"
                  show-overflow-tooltip
                />
                <el-table-column
                  :label="$t('AddMachine.GroupName')"
                  prop="group_name"
                  min-width="22%"
                  show-overflow-tooltip
                />
                <el-table-column
                  :label="$t('AddMachine.EquipmentModel')"
                  prop="product_model"
                  min-width="22%"
                  show-overflow-tooltip
                />
                <el-table-column
                  :label="$t('AddMachine.EquipmentNumber')"
                  prop="number"
                  min-width="22%"
                  show-overflow-tooltip
                />
                <el-table-column
                  :label="$t('AddMachine.Enable')"
                  min-width="22%"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="0"
                      inactive-color="#9E9E9E"
                      @change="changeMachine(scope.row)"
                  /></template>
                </el-table-column>
                <el-table-column
                  :label="$t('AddMachine.Image')"
                  prop="image"
                  min-width="22%"
                  align="left"
                >
                  <template slot-scope="scope">
                    <el-popover
                      placement="top-start"
                      trigger="hover"
                      popper-class="area_popper"
                    >
                      <a
                        :href="scope.row.image"
                        target="_blank"
                        :title="$t('Setting.Biggest')"
                        ><img
                          :src="scope.row.image"
                          style="width: 200px; height: 200px"
                      /></a>
                      <img
                        v-if="scope.row.image != ''"
                        slot="reference"
                        :src="scope.row.image"
                        class="machineImage"
                        style="margin-left: -10px"
                      />
                    </el-popover>
                    <img
                      v-if="scope.row.image == ''"
                      src="../../../assets/image/mainDefault.png"
                      class="machineImage"
                      style="margin-left: -10px"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('AddMachine.Operation')"
                  width="140"
                  align="left"
                >
                  <template slot-scope="scope">
                    <img
                      src="../../../assets/image/bianji_main@2x.png"
                      @click="handleEditOrder(scope.row)"
                      class="OperationImg"
                      style="margin-right: 30px"
                    />
                    <img
                      src="../../../assets/image/shanchu_mian@2x.png"
                      @click="handleDeleteOrder(scope.row)"
                      class="OperationImg"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column min-width="81%" :label="$t('Machine.Workshop')">
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('AddMachine.Operation')"
            width="120"
            align="left"
          >
            <template slot-scope="scope">
              <img
                data-custom-name="addGroup"
                src="../../../assets/image/add.png"
                @click="handleAddOrder(scope.row)"
                class="OperationImg"
              />
            </template>
          </el-table-column>

          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-size="pagesize"
          :page-sizes="[5, 10, 15]"
          :current-page.sync="page"
          :total="total"
          v-show="total && total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <el-dialog
        :title="
          type == 'edit'
            ? $t('Setting.EditEquipment')
            : $t('Setting.AddEquipment')
        "
        :visible.sync="machineSet.dialogVisible"
        width="732px"
        class="dialog"
      >
        <el-form :model="machineForm" label-width="20px">
          <div class="from-box">
            <div class="left-box">
              <el-form-item prop="machine_name">
                <div class="label">{{ $t("AddMachine.EquipmentName") }}</div>
                <el-input
                  v-model.trim="machineForm.machine_name"
                  auto-complete="off"
                  :placeholder="$t('Machine.EquipmentName')"
                  clearable
                  @input="changeIpt($event)"
                />
              </el-form-item>
              <el-form-item prop="workshop_id">
                <div class="label">{{ $t("Machine.Workshop") }}</div>
                <el-select
                  v-model="machineForm.workshop_id"
                  @change="changeWorkshopDialog"
                >
                  <el-option
                    :label="item.name"
                    v-model="item.id"
                    v-for="item in workshopListTan"
                    :key="item.id"
                    :placeholder="$t('Setting.WorkshopInput')"
                  />
                </el-select>
              </el-form-item>
              <el-form-item prop="group_id">
                <div class="label">{{ $t("Machine.EquipmentGroup") }}</div>
                <el-select v-model="machineForm.group_id" @change="changeGroup">
                  <el-option
                    :label="item.name"
                    v-model="item.id"
                    v-for="item in groupListTan"
                    :key="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item prop="machine_type">
                <div class="label">{{ $t("Machine.Model") }}</div>
                <el-input
                  v-model.trim="machineForm.machine_type"
                  auto-complete="off"
                  :placeholder="$t('Setting.InputModel')"
                  clearable
                  @input="changeIpt($event)"
                  @keyup.native="btKeyUp"
                />
              </el-form-item>
              <el-form-item prop="number">
                <div class="label">{{ $t("AddMachine.EquipmentNumber") }}</div>
                <el-input
                  v-model.trim="machineForm.number"
                  auto-complete="off"
                  :disabled="machineSet.dialogStatus == 'Edit'"
                  class="copy_input"
                  :placeholder="$t('AddMachine.EquipmentNumberInput')"
                  clearable
                  @input="changeIpt($event)"
                />
                <div style="float: right"></div>
              </el-form-item>
            </div>
            <div class="right-box">
              <el-form-item prop="name">
                <div class="label" style="text-indent: 12px">
                  {{ $t("Setting.UploadImage") }}
                </div>
                <div class="flex-img">
                  <div
                    class="el-upload-list el-upload-list--picture-card"
                    v-show="hideUpload"
                  >
                    <div class="el-upload-list__item is-success">
                      <img
                        class="flex-img__image"
                        :src="
                          machineSet.machineImage
                            ? machineSet.machineImage
                            : require('../../../assets/image/mainDefault.png')
                        "
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete">
                          <i
                            class="el-icon-delete"
                            @click.stop="handleRemove()"
                          ></i>
                        </span>
                      </span>
                    </div>
                  </div>
                  <el-upload
                    class="image-uploader"
                    action=""
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload"
                    accept=".jpg,.jpeg,.png"
                    v-if="!hideUpload"
                    :on-remove="handleRemove"
                    :http-request="uploadMachine"
                  >
                    <img
                      v-if="machineSet.machineImage"
                      :src="machineSet.machineImage"
                      class="image"
                      alt=""
                    />
                    <i v-else class="el-icon-plus image-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <div
            class="btn-normal pan-btn tiffany-btn bts"
            @click="handleConfirm()"
            style="margin-right: 0px; background: #4875ef"
            :style="{ background: isBlue ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Login.OK") }}
          </div>
          <div
            class="btn-normal pan-btn blue-btn bts"
            @click="machineSet.dialogVisible = false"
            style="background: #767e95"
          >
            {{ $t("AddMachine.Cancel") }}
          </div>
          <div
            class="btn-normal pan-btn tiffany-btn"
            @click="machineSet.changeCode = true"
            style="background: #767e95; min-width: 70px; padding: 0 10px"
            v-show="type == 'edit' && (rid == 3 || rid == 4)"
          >
            {{ $t("Setting.MatchingCode") }}
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('Login.Account')"
        :visible.sync="machineSet.codes"
        width="364px"
        class="dialogs"
      >
        <div class="form-box" style="text-align: left; margin-bottom: 10px">
          {{ account }}
        </div>
        <el-form ref="workShopForm" :model="codes" label-width="0px">
          <div class="form-box">
            <el-form-item prop="name">
              <div class="label" style="text-align: left; font-size: 18px">
                {{ $t("Login.Password") }}
              </div>
              <el-input
                v-model.trim="codes.machineCode"
                type="password"
                :placeholder="$t('Login.inputPwd')"
                auto-complete="off"
                clearable
              />
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <div
            class="btn-normal pan-btn blue-btn bts"
            style="margin-right: 0px; background: #4875ef"
            @click="confireCode"
          >
            {{ $t("Login.OK") }}
          </div>
          <div
            class="btn-normal pan-btn tiffany-btn bts"
            @click="machineSet.codes = false"
            style="background: #767e95"
          >
            {{ $t("AddMachine.Cancel") }}
          </div>
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('TempTab.EquipmentMatchingCode')"
        :visible.sync="machineSet.changeCode"
        width="364px"
        class="dialogs"
      >
        <div class="machineCode">{{ ProductCode }}</div>
        <div class="buttons">
          <div class="cgCode">
            <div
              class="cgCodeText"
              v-clipboard:copy="ProductCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              style="background: rgb(118, 126, 149)"
            >
              {{ $t("Setting.CopyCode") }}
            </div>
          </div>
          <div class="cgCode">
            <div class="cgCodeText" @click="setCode">
              {{ $t("Setting.ChangeCode") }}
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getMachine,
  deleteMachine,
  uploadMachines,
  addMachine,
  editMachine,
  changeMatchingCode,
  listSearch,
  changeMachine,
} from "@/api/machine";

import "../../../style/table.less";

export default {
  name: "machine",
  data() {
    return {
      Startime: "",
      Endtime: "",
      ProductCode: "",
      codes: {
        machineCode: "",
      },
      workshopList: [],
      groupList: [],
      workshopListTan: [],
      groupListTan: [],
      machineItem: [],
      machineArray: [],
      workshop: "0",
      workInfo: [],
      workInfoTan: [],
      group: "0",
      isClear: false,
      rid: 4,
      machine: "",
      serial_number: "",
      account: "",
      machineForm: {
        // 表单数据
        group_id: "",
        machine_image: "",
        machine_name: "",
        machine_type: "",
        number: "",
        id: "",
        workshop_id: "",
      },
      type: "",
      repeat: true,
      repeatCopy: true,
      isBlueSearch: false,
      hideUpload: false,
      machineSet: {
        dialogStatus: "Add", // 弹窗操作状态
        dialogTitle: "", // 弹窗标题
        dialogVisible: false, // 弹窗状态
        changeCode: false,
        codes: false,
        machineImage: "", // 上传的设备图片
      },
      isBlue: false,
      repeatPwd: true,
      tableData: [],
      expandRowKeys: [],
      total: 0,
      page: 1,
      pagesize: 15,
      language: "",
    };
  },
  created() {
    this.getMachine("", "type");
    this.listSearch("init");

    this.account = localStorage.getItem("account");
    this.rid = localStorage.getItem("rid");
    this.language = localStorage.getItem("language");
  },
  watch: {
    workshop(value) {
      if (parseInt(value) === 0) {
        this.workshopList = [];
        this.listSearch();
      }
    },
    machine(val) {
      if (val) {
        this.getMachineName(val);
      } else {
        this.machineArray = [];
      }
    },
    "$i18n.locale"(val) {
      this.changeLan();
      this.changeWorkshopLan();
      this.changeWorkshopLanSec();
    },
  },
  methods: {
    querySearch(queryString, cb) {
      this.machineItem = this.machineItem.map((item) => {
        return {
          value: item.name,
          ...item,
        };
      });
      var machineArray = this.machineItem;
      var results = queryString
        ? machineArray.filter(this.createFilter(queryString))
        : machineArray;
      cb(results);
    },
    createFilter(queryString) {
      return (data) => {
        return data.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    changeWorkshopLanSec() {
      if (this.workshopListTan.length > 0) {
        if (
          this.workshopListTan[0].name === "默认车间" ||
          this.workshopListTan[0].name === "Default Workshop"
        ) {
          this.$set(this.workshopListTan[0], "name", this.$t("Tip.Default"));
        }
      }
    },
    changeWorkshopLan() {
      if (this.workshopList.length > 0) {
        if (
          this.workshopList[0].name === "默认车间" ||
          this.workshopList[0].name === "Default Workshop"
        ) {
          this.$set(this.workshopList[0], "name", this.$t("Tip.Default"));
        }
      }
    },
    changeLan() {
      if (this.tableData.length > 0) {
        if (
          this.tableData[0].name === "默认车间" ||
          this.tableData[0].name === "Default Workshop"
        ) {
          this.$set(this.tableData[0], "name", this.$t("Tip.Default"));
        }
      }
    },
    blueIpt() {
      setTimeout(() => {
        this.machineArray = [];
        this.isClear = false;
      }, 20);
    },
    focusIpt() {
      setTimeout(() => {
        this.isClear = true;
      }, 20);
    },
    cleareMachine() {
      this.machine = "";
    },
    selectName(val) {
      this.machine = val;
      setTimeout(() => {
        this.machineArray = [];
      }, 20);
    },
    btKeyUp(e) {
      // eslint-disable-next-line no-useless-escape
      e.target.value = e.target.value.replace(
        /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
        ""
      );
    },
    beforeAvatarUpload(file) {
      const types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.warning(this.$t("Tip.imageLimit"));
      } else {
      }
    },
    onCopy() {
      if (!this.repeatCopy) {
        return;
      }
      this.repeatCopy = false;
      setTimeout(() => {
        this.repeatCopy = true;
      }, 2000);
      this.$message({
        showClose: true,
        message: this.$t("Tip.CopySuccessful"),
        type: "success",
        duration: 1000,
      });
    },
    onError() {
      this.$message({
        showClose: true,
        message: this.$t("Tip.CopyFailed"),
        type: "error",
        duration: 1000,
      });
    },
    changeIpt(e) {
      this.$forceUpdate();
    },
    changeMachine(item) {
      const params = {};
      params.id = item.id.toString();
      params.show = item.status === 1;
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      changeMachine(params, token, lang).then((res) => {});
    },
    getMachineName(value) {
      this.machineArray = [];
      if (value !== "") {
        const ary = this.machineItem;
        if (ary) {
          for (var j = 0; j < ary.length; j++) {
            if (ary[j].name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
              this.machineArray.push(ary[j]);
            }
          }
        }
      }
    },
    changeGroup() {
      this.$forceUpdate();
      this.machineItem = [];
      this.machineArray = [];
      this.machine = "";
      if (parseInt(this.group) === 0) {
        for (var i = 0; i < this.workInfo.length; i++) {
          for (var j = 0; j < this.workInfo[i].device_group.length; j++) {
            for (
              var k = 0;
              k < this.workInfo[i].device_group[j].devices.length;
              k++
            ) {
              this.machineItem.push(
                this.workInfo[i].device_group[j].devices[k]
              );
            }
          }
        }
      } else {
        for (var m = 0; m < this.workInfo.length; m++) {
          for (var n = 0; n < this.workInfo[m].device_group.length; n++) {
            if (
              parseInt(this.group) ===
              parseInt(this.workInfo[m].device_group[n].id)
            ) {
              for (
                var s = 0;
                s < this.workInfo[m].device_group[n].devices.length;
                s++
              ) {
                this.machineItem.push(
                  this.workInfo[m].device_group[n].devices[s]
                );
              }
            }
          }
        }
      }
    },
    changeWorkshopDialog() {
      // this.groupListTan = [];
      // this.machineForm.group_id = "";
      for (var i = 0; i < this.workInfoTan.length; i++) {
        if (
          parseInt(this.machineForm.workshop_id) ===
          parseInt(this.workInfoTan[i].id)
        ) {
          this.groupListTan = [...this.workInfoTan[i].device_group];
        }
      }
      // this.machineForm.group_id =
      // this.groupListTan.length > 0 ? this.groupListTan[0].id : 1;
    },
    changeWorkshop() {
      this.groupList = [];
      this.machineArray = [];
      this.machine = "";
      this.group = "";
      for (var i = 0; i < this.workInfo.length; i++) {
        if (parseInt(this.workshop) === this.workInfo[i].id) {
          this.groupList = this.workInfo[i].device_group;
        }
      }
      this.group = this.groupList.length > 0 ? this.groupList[0].id : 1;
      this.changeGroup();
    },
    async listSearchSec(type) {
      const params = {};
      params.query_level = 1;
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      await listSearch(params, token, lang).then((res) => {
        this.workInfoTan = res.data;
        for (var i = 0; i < this.workInfoTan.length; i++) {
          const workshopItem = {
            id: "",
            name: "",
          };
          workshopItem.id = this.workInfoTan[i].id;
          workshopItem.name = this.workInfoTan[i].name;
          this.workshopListTan.push(workshopItem);
          for (var j = 0; j < this.workInfoTan[i].device_group.length; j++) {
            this.groupListTan.push(this.workInfoTan[i].device_group[j]);
          }
        }
        // this.changeWorkshopLanSec();
        // this.machineForm.group_id = "";
      });
      if (type) {
        this.changeWorkshopDialog();
        if (type == "add") {
          this.machineForm.group_id = this.groupListTan?.[0]?.id ?? "";
        }
      }
    },
    async listSearch(val) {
      const params = {};
      params.query_level = 2;
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      await listSearch(params, token, lang).then((res) => {
        this.workInfo = res.data;
        for (var i = 0; i < this.workInfo.length; i++) {
          const workshopItem = {
            id: "",
            name: "",
          };
          workshopItem.id = this.workInfo[i].id;
          workshopItem.name = this.workInfo[i].name;
          this.workshopList.push(workshopItem);
          for (var j = 0; j < this.workInfo[i].device_group.length; j++) {
            this.groupList.push(this.workInfo[i].device_group[j]);
          }
        }
        if (val) {
          this.changeGroup();
        }
        this.changeWorkshopLan();
        this.group = "0";
      });
    },
    getGroup() {},
    setCode() {
      this.machineSet.changeCode = false;
      this.machineSet.codes = true;
    },
    confireCode() {
      if (!this.repeatPwd) {
        return;
      }
      this.repeatPwd = false;
      setTimeout(() => {
        this.repeatPwd = true;
      }, 2000);
      const params = {};
      params.id = this.machineForm.id;
      params.password = this.codes.machineCode;
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      if (!this.codes.machineCode) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.IpPwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      changeMatchingCode(params, token, lang).then((response) => {
        if (response.code === 200) {
          this.ProductCode = response.data.matching_code;
          this.machineSet.codes = false;
          this.machineSet.changeCode = true;
        }
      });
    },
    handleConfirm() {
      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      const params = {};
      if (!this.repeat) {
        return;
      }
      this.repeat = false;
      setTimeout(() => {
        this.repeat = true;
      }, 2000);
      if (!this.machineForm.machine_name) {
        this.$message({
          showClose: true,
          message: this.$t("Machine.EquipmentName"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.machineForm.machine_name.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.equipmentExceed"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.machineForm.workshop_id) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.WorkshopInput"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.machineForm.group_id) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.equipmentGroup"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.machineForm.machine_type) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.InputModel"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.machineForm.machine_type.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.modelExceed"),
          type: "warning",
          duration: 1000,
        });
        return;
      }

      if (!this.machineForm.number) {
        this.$message({
          showClose: true,
          message: this.$t("AddMachine.EquipmentNumberInput"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.type === "add") {
        params.name = this.machineForm.machine_name;
        params.wid = this.machineForm.workshop_id;
        params.group_id = this.machineForm.group_id;
        params.product_model = this.machineForm.machine_type;
        params.number = this.machineForm.number;
        params.image = this.machineForm.machine_image;
        const token = localStorage.getItem("token");
        const lang = localStorage.getItem("language");
        addMachine(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.AddSuccess"),
              type: "success",
              duration: 1000,
            });
            this.machineSet.dialogVisible = false;
            this.getMachine("", "type", "add");
            this.listSearch("init");
          }
        });
      } else {
        params.id = this.machineForm.id;
        params.name = this.machineForm.machine_name;
        params.wid = this.machineForm.workshop_id;
        params.group_id = this.machineForm.group_id;
        params.product_model = this.machineForm.machine_type;
        params.number = this.machineForm.number;
        params.image = this.machineForm.machine_image;
        const token = localStorage.getItem("token");
        const lang = localStorage.getItem("language");
        editMachine(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.EditSuccess"),
              type: "success",
              duration: 1000,
            });
          }
          this.machineSet.dialogVisible = false;
          this.getMachine("", "type", "edit");
          this.listSearch("init");
        });
      }
    },
    // 移除图片事件
    handleRemove(file, fileList) {
      this.hideUpload = false;
      this.machineForm.machine_image = "";
      this.machineSet.machineImage = "";
    },
    // 上传设备图片
    uploadMachine(data) {
      const file = data.file;
      const formData = new FormData();
      formData.append("image", file);
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      uploadMachines(formData, token, lang).then((response) => {
        if (response.code === 200) {
          this.machineForm.machine_image = response.data.url;
          this.machineSet.machineImage = URL.createObjectURL(data.file);
        }
      });
    },
    async handleEditOrder(row) {
      this.machineSet.dialogVisible = true;
      this.workshopListTan = [];
      this.groupListTan = [];
      this.hideUpload = true;
      this.type = "edit";
      this.ProductCode = row.matching_code;
      this.machineForm.id = row.id;
      this.machineForm.group_id = row.group_id;
      this.machineForm.machine_image = row.image;
      this.machineForm.machine_name = row.name;
      this.machineForm.machine_type = row.product_model;
      this.machineForm.number = row.number;
      this.machineSet.machineImage = row.image;
      this.machineForm.workshop_id = row.wid;
      await this.listSearchSec("edit");
    },
    async handleAddOrder(row) {
      this.workshopListTan = [];
      this.groupListTan = [];
      this.machineForm = {};
      this.hideUpload = false;
      this.machineSet.machineImage = "";
      this.machineSet.dialogVisible = true;
      this.machineForm.workshop_id = row.id;
      this.type = "add";
      await this.listSearchSec("add");
    },
    handleDeleteOrder(val) {
      this.$confirm(this.$t("Tip.DeleteEquipment"), this.$t("Tip.Tips"), {
        confirmButtonText: this.$t("Login.OK"),
        cancelButtonText: this.$t("AddMachine.Cancel"),
      })
        .then(() => {
          const params = {};
          params.id = val.id;
          const token = localStorage.getItem("token");
          const lang = localStorage.getItem("language");
          deleteMachine(params, token, lang).then((response) => {
            if (response.code === 200) {
              this.$message({
                showClose: true,
                message: this.$t("Setting.RemovedSuccessfully"),
                type: "success",
                duration: 1000,
              });
            }
            this.getMachine("", "type", "delete");
            this.listSearch("init");
          });
        })
        .catch(() => {});
    },
    searchMachine() {
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      const ts =
        parseInt(this.group) !== 0 || this.machine || this.serial_number
          ? "ts"
          : "";
      this.machineArray = [];
      this.getMachine(ts, "", "");
    },
    getMachine(val, type, item) {
      const loading = this.$loading({
        lock: true,
        text: this.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {};
      params.page = this.page;
      params.pagesize = this.pagesize;
      params.name = this.machine;
      params.group_id = parseInt(this.group) === 0 ? "" : this.group;
      if (parseInt(this.workshop) !== 0) {
        params.wids = this.workshop;
      }
      if (parseInt(this.group) !== 0) {
        for (var i = 0; i < this.workInfo.length; i++) {
          for (var j = 0; j < this.workInfo[i].device_group.length; j++) {
            if (
              parseInt(this.group) ===
              parseInt(this.workInfo[i].device_group[j].id)
            ) {
              params.wids = this.workInfo[i].id;
            }
          }
        }
      }
      params.number = this.serial_number;
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("language");
      getMachine(params).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.count;
        this.changeLan();
        if (!item) {
          this.expandRowKeys = [];
        }
        if (val) {
          for (var i = 0; i < this.tableData.length; i++) {
            this.expandRowKeys.push(this.tableData[i].id);
          }
        }
      });

      loading.close();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pagesize = val;
      this.getMachine("", "type");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getMachine("", "type");
    },
    row_key(row) {
      return row.id;
    },
    expandChange(row, rowList) {
      this.expandRowKeys = [];
      rowList.forEach((item) => {
        this.expandRowKeys.push(item.id);
      });
    },
    rowClick(row, event, column) {
      // eslint-disable-next-line no-extend-native
      Array.prototype.remove = function (val) {
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (column.target.dataset?.customName == "addGroup") return;
      if (this.expandRowKeys.indexOf(row.id) < 0) {
        this.expandRowKeys.push(row.id);
      } else {
        this.expandRowKeys.remove(row.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.spm_size {
  position: absolute;
  top: 39px;
  z-index: 15;
  width: 0;
  z-index: 10001;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #fff;
}
.clear {
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 5px;
  font-weight: bold;
}
.Manual_search_left {
  -webkit-appearance: none;
  height: 32px !important;
  line-height: 32px !important;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.search_dia_list {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  text-align: left;
}
.search_dia {
  width: 213px;
  max-height: 220px;
  border-radius: 4px;
  position: absolute;
  padding: 5px 0 0 0;
  left: 0px;
  top: 45px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%) !important;
}
.search_dia::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.search_dia::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  height: 2px;
  background-color: #fff;
  cursor: pointer;
}
/*定义滚动条轨道 内阴影+圆角*/
.search_dia::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
.search_dia::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.5);
  background-color: #eee;
}
@media (max-width: 1367px) {
  .machine_search_list {
    flex: 2.2 !important;
  }
  .machine_search_space {
    flex: 0 !important;
  }
  .searchList {
    flex: 0.5 !important;
  }
  .search_dia {
    width: 168px;
  }
  .Manual_search_left {
    width: 168px;
  }
}
@media (min-width: 1367px) and (max-width: 1441px) {
  .machine_search_list {
    flex: 1.75 !important;
  }
  .Manual_search_left {
    width: 148px;
  }
  .searchList {
    flex: 1 !important;
  }
  .machine_search_space {
    flex: 0.5 !important;
  }
  .search_dia {
    width: 148px;
    left: 0px;
  }
}
@media (min-width: 1441px) and (max-width: 1601px) {
  .machine_search_list {
    flex: 2.75 !important;
  }
  .searchList {
    flex: 1 !important;
  }
  .machine_search_space {
    flex: 0.2 !important;
  }
  .Manual_search_left {
    width: 196px;
  }
  .search_dia {
    width: 196px;
  }
}
@media (min-width: 1601px) and (max-width: 1919px) {
  .machine_search_list {
    flex: 2 !important;
  }
  .searchList {
    flex: 1.2 !important;
  }
  .machine_search_space {
    flex: 0.2 !important;
  }
  .Manual_search_left {
    width: 196px;
  }
  .search_dia {
    width: 196px;
  }
}
.pagination-container {
  margin: 20px 25px;
  float: left;
}
.buttons {
  display: flex;
  width: 70%;
  margin-left: 15%;
  .cgCode {
    width: 100%;
    display: flex;
    justify-content: center;
    .cgCodeText {
      min-width: 99px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 15.5px;
      padding: 0 10px;
      background: #4875ef;
      color: #f2f3f6;
      font-size: 14px;
      margin: 10px 0;
      cursor: pointer;
    }
  }
}

.machineCode {
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 32px;
  letter-spacing: 0.2em;
  color: #2b2b2b;
}
.dialog /deep/ .el-form-item__content {
  line-height: 35px;
}
.dialog /deep/ .el-input__icon {
  line-height: 35px;
}
.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.dialog /deep/ .el-button--success:hover {
  background: #409eff;
  border-color: #409eff !important;
}
.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialogs /deep/ .el-dialog {
  border-radius: 20px !important;
  margin-top: 30vh !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialogs /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}
.dialogs /deep/ .el-form-item__content {
  line-height: 35px;
}
.btn-normal {
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.dialogs /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.dialogs /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.OpeImg {
  display: flex;
  justify-content: center;
}
.app-wrapper /deep/ .el-table__empty-text {
  line-height: 20px !important;
  font-weight: bold;
  font-size: 16px;
  color: #909399;
}
.el-input--mini .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}

@media screen and (max-width: 1367px) {
  .table-container {
    height: calc(100vh - 365px);
  }
  .ptse {
    margin-top: 20px !important;
  }
  .app-wrapper /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .dialog /deep/ .el-dialog {
    border-radius: 20px !important;
    margin-top: 12vh !important;
  }
  .app-wrapper .search-panel .search-box /deep/ .el-input__inner {
    width: 118px !important;
    font-size: 12px !important;
  }
  //  .app-wrapper /deep/.el-input--suffix {
  //     width: 118px !important;
  //     font-size: 12px !important;
  //   }
  .app-wrapper .search-panel .search-box .search-el {
    width: 118px !important;
  }
  .app-wrapper .search-panel .search-box .label {
    font-size: 12px !important;
    height: 10px !important;
    line-height: 10px !important;
  }
}
.table-container {
  overflow-y: auto;
  width: 100%;
}
@media (min-width: 1368px) and (max-width: 1600px) {
  .app-wrapper /deep/ .el-form-item {
    margin-bottom: 15px;
  }
  .table-container {
    height: calc(100vh - 365px);
  }
  .app-wrapper .search-panel .search-box .label {
    font-size: 16px !important;
  }
  .table-container /deep/ .el-table th .cell {
    font-size: 16px !important;
  }
  .dialog /deep/ .el-dialog {
    margin-top: 16vh !important;
  }
  //  .app-wrapper /deep/.el-input--suffix {
  //     width: 148px !important;
  //     font-size: 13px !important;
  //   }
  .app-wrapper .search-panel .search-box /deep/ .el-input__inner {
    width: 148px !important;
    font-size: 13px !important;
  }
}

@media screen and (min-width: 1601px) {
  .table-container {
    height: calc(100vh - 365px);
  }
  .app-wrapper .search-panel .search-box /deep/ .el-input__inner {
    width: 148px !important;
    font-size: 13px !important;
  }
}
.app-wrapper .from-box .label[data-v-dad8d5b0] {
  line-height: 30px;
}
.dialog /deep/ .from-box .add-panel .add-icon-box {
  left: 10px !important;
}
.dialog /deep/ .label {
  text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}
.dialogs /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}
.dialog /deep/ .el-dialog__header {
  padding: 20px 40px 0px;
  text-align: left;
}
.dialogs /deep/ .el-dialog__header {
  text-align: left;
}
.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}
.dialog /deep/ .el-dialog__body .el-input {
  width: 480px !important;
}
.OperationImg {
  width: 24px;
  height: 24px;
  float: left;
  cursor: pointer;
}
.table-container /deep/ .el-icon-arrow-right {
  margin-left: -8px;
  margin-top: -8px;
  font-size: 18px !important;
}
.app-wrapper /deep/ .pan-btn {
  // width: 70px;
  height: 32px !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 34px !important;
  font-weight: 400 !important;
  text-align: center !important;
  color: #fff !important;
  padding: 0px;
  // background-color:#4875ef !important;
}
.app-wrapper /deep/ .bts {
  width: 70px;
}
.app-wrapper {
  .search-panel {
    .search-box {
      display: inline-block;
      margin-right: 10px;
      float: right;
      .label {
        font-weight: 400;
        color: #4d4f5c;
        margin-bottom: 10px;
      }
      // /deep/.el-select {
      //   width: 170px;
      // }
      /deep/.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      /deep/.el-input__icon {
        line-height: 35px;
      }
    }
  }
  .from-box {
    display: flex;
    justify-content: space-between;

    .label {
      font-size: 18px;
      font-weight: 400;
      color: #4d4f5c;
    }
    .add-panel {
      display: flex;
    }
    .left-box {
      flex-basis: 60%;
      /deep/.el-input {
        width: 380px;
      }
      /deep/.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .right-box {
      flex-basis: 40%;
      //text-align: center;
      /deep/.el-input {
        width: 164px;
      }
      /deep/.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      .flex-img {
        .flex-img__image {
          width: 146px;
          height: 146px;
          border-radius: 6px;
          display: block;
        }
        .image-uploader /deep/.el-upload {
          background-color: #fbfdff;
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .image-uploader /deep/.el-upload:hover {
          border-color: #4875ef;
        }
        .image-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 146px;
          height: 146px;
          line-height: 146px;
          text-align: center;
        }
        .image {
          width: 146px;
          height: 146px;
          display: block;
        }
      }
    }
    /deep/.hide .el-upload--picture-card {
      display: none;
    }
  }
  // .pagination-container{
  //   margin: 25px;
  // }
  .table-container {
    // min-height: calc(100vh - 288.5px);
    .el-table::before {
      height: 0px;
    }
    /deep/.el-table__body-wrapper {
      // max-height: calc(100vh - 445px);
      overflow-y: auto;
    }
    /deep/.el-table__empty-text {
      margin-top: 0;
      .empty_text {
        // font-weight: 700;
        // color: #929292;
        // margin-top: -25px;
      }
    }
  }
  .el-table {
    .machineImage {
      width: 28px;
      height: 28px;
      border-radius: 5px;
      margin-left: 6px !important;
    }
    .pan-btn {
      margin: 0px;
      padding: 6px 22px;
    }
  }
  // /deep/.el-dialog {
  //   margin-bottom: 5vh;
  // }
}
.data_empty /deep/ .empty_text {
  margin-top: 0px !important;
}
.data_empty {
  padding-top: 90px;
}
.app-wrapper .from-box .right-box .flex-img .image-uploader /deep/ .el-upload {
  margin-top: 5px;
}
.search-box img {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 0px;
  cursor: pointer;
  margin-top: 10px;
}
.dialog /deep/.el-dialog__body .copy_input {
  width: 480px !important;
}
.table-container /deep/ .el-table .el-table__empty-text {
  display: none;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;
    .table_content {
      height: calc(100vh - 270px);
      overflow: auto;
      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #ebecee;
      }
      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
    }
    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 10px 25px;

      .machine_search_list {
        flex: 1.2;
        margin-right: 5px;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }
        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;
          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }
          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }

          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }
          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }
          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
          .seriese /deep/ .el-input__icon {
            margin-top: -3px;
          }
        }
      }
      .machine_search_btn {
        flex: 0.55;
        .search_btn {
          width: 70px;
          height: 32px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 32px;
          border-radius: 30px;
          margin-top: 10px;
          cursor: pointer;
        }
        .edi_color {
          background-color: #767e95;
        }
      }
      .machine_search_space {
        flex: 1.22;
      }
    }
  }
}
</style>
