<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_search">
        <div class="machine_search_list">
          <div class="search_title">{{ $t("AddMachine.GroupSimple") }}</div>
          <div class="search_selected" style="position: relative; width: 206px">
            <!-- <el-input
              :label="$t('AddMachine.GroupSimple')"
              v-model.trim="groupName"
              :placeholder="$t('Setting.equipmentGroup')"
              clearable
            /> -->
            <el-autocomplete
              v-model.trim="groupName"
              :fetch-suggestions="querySearch"
              :placeholder="$t('AddMachine.GroupSimple')"
              style="width: 100%"
              @blur="blueIpt"
              @focus="focusIpt"
            ></el-autocomplete>
            <img
              src="../../../assets/image/clearable.png"
              @mousedown.stop="clearInput"
              class="clear"
              v-show="groupName && isClear"
            />
            <!--              "
             -->
          </div>
        </div>
        <div class="machine_search_btn">
          <div
            class="search_btn"
            @click="searchGroup"
            :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Machine.Search") }}
          </div>
        </div>
        <div class="machine_search_btn" style="margin-left: 10px">
          <div
            class="search_btn"
            @click="reset"
            :style="{ background: isBlue ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Warning.Reset") }}
          </div>
        </div>
        <div class="machine_search_space"></div>
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          @row-click="rowClick"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          style="width: 100%"
          row-key="id"
          :expand-row-keys="expandRowKeys"
          @expand-change="expandChange"
        >
          <el-table-column width="10" align="center" />
          <el-table-column type="expand" min-width="10%">
            <template slot-scope="props">
              <el-table
                ref="multipleTable"
                :data="props.row.device_group"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ background: '#dde0e8' }"
              >
                <el-table-column width="70" align="center" />
                <el-table-column
                  :label="$t('Setting.GroupName')"
                  prop="name"
                  min-width="15%"
                  show-overflow-tooltip
                />
                <el-table-column
                  :label="$t('Setting.GroupQuantity')"
                  prop="device_count"
                  min-width="80%"
                  show-overflow-tooltip
                />
                <el-table-column
                  :label="$t('AddMachine.Operation')"
                  width="140"
                  align="left"
                >
                  <template slot-scope="scope">
                    <img
                      src="../../../assets/image/bianji_main@2x.png"
                      @click="handleEditOrder(scope.row)"
                      class="OperationImg"
                    />
                    <img
                      src="../../../assets/image/shanchu_mian@2x.png"
                      @click="handleDeleteOrder(scope.row)"
                      class="OperationImg"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
            min-width="15%"
            :label="$t('Machine.Workshop')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80%"
            :label="$t('Setting.GroupQuantity')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.group_count }}</div>
            </template>
          </el-table-column>
          <el-table-column
            l:label="$t('AddMachine.Operation')"
            width="120"
            align="left"
          >
            <template slot-scope="scope">
              <img
              data-custom-name="addGroup"
                src="../../../assets/image/add.png"
                @click="handleAddOrder(scope.row)"
                class="OperationImg"
              />
            </template>
          </el-table-column>

          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-size="pagesize"
          :page-sizes="[5, 10, 15]"
          :current-page.sync="page"
          :total="total"
          v-show="total && total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      :title="type == 'edit' ? $t('Setting.editGroup') : $t('Setting.addGroup')"
      :visible.sync="dialogVisible"
      width="364px"
      class="dialog"
    >
      <el-form ref="workShopForm" :model="group" label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Machine.EquipmentGroup") }}
            </div>
            <el-input
              v-model.trim="group.name"
              :placeholder="$t('Setting.equipmentGroup')"
              auto-complete="off"
              clearable
              @input="changeIpt($event)"
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn"
          style="margin-right: 0px; background: #4875ef"
          @click="handleConfirm(group)"
          :style="{ background: isBlueConfire ? '#3660DD' : '#4875ef' }"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn"
          @click="dialogVisible = false"
          style="background: #767e95"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getErrorMoldList } from '../../api/warning'
import { getGroup, deleteGroup, addGroup, editGroup } from "@/api/machine";
export default {
  name: "Group",
  data() {
    return {
      Startime: "", // 开始时间
      Endtime: "", // 结束时间
      dialogVisible: false, // 弹窗是否打开
      groupName: "", // 组名称
      workShopId: "", // 车间ID
      repeat: true,
      repeatSearch: true,
      type: "", // 新增还是编辑
      // form表达组名称
      group: {
        name: "",
      },
      tableData: [], // 车间群组数组列表对象
      expandRowKeys: [], // 折叠父ID数组
      isBlue: false, // 点击按钮瞬间变色对象
      isBlueSearch: false, // 点击按钮瞬间变色对象
      isBlueConfire: false, // 点击按钮瞬间变色对象
      total: 0, // 总数（用于分页插件）
      page: 1,
      pagesize: 15,
      groupNameList: [], //设备组名称数组，供模糊搜索
      isClear: false,
    };
  },
  created() {
    this.getGroup("", "type").then((data) => {
      // 只需要加载一次
      this.groupNameList = [];
      data.forEach((item) => {
        item.device_group.forEach((group) => {
          this.groupNameList.push({
            value: group.name,
            ...group,
          });
        });
      });
    });
  },
  watch: {
    // 监听语言栏变化
    "$i18n.locale"(val) {
      this.changeLan();
    },
  },
  methods: {
    blueIpt() {
      setTimeout(() => {
        this.isClear = false;
      }, 20);
    },
    focusIpt() {
      setTimeout(() => {
        this.isClear = true;
      }, 20);
    },
    clearInput() {
      this.groupName = "";
    },
    // 默认车间多语言变化
    changeLan() {
      if (this.tableData.length > 0) {
        if (
          this.tableData[0].name === "默认车间" ||
          this.tableData[0].name === "Default Workshop"
        ) {
          this.$set(this.tableData[0], "name", this.$t("Tip.Default"));
        }
      }
    },
    // 输入框输入事件强制更新
    changeIpt(e) {
      this.$forceUpdate();
    },
    reset() {
      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      this.groupName = "";
      this.getGroup("", "type");
    },
    handleConfirm(group) {
      this.isBlueConfire = true;
      setTimeout(() => {
        this.isBlueConfire = false;
      }, 500);
      const params = {};
      if (!this.repeat) {
        return;
      }
      this.repeat = false;
      setTimeout(() => {
        this.repeat = true;
      }, 2000);
      if (!this.group.name) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.equipmentGroup"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.group.name.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.GroupExceed"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.type === "add") {
        params.name = group.name;
        params.wid = this.workShopId;
        const token = localStorage.getItem("token");
        const lang = localStorage.getItem("language");
        addGroup(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.AddSuccess"),
              type: "success",
              duration: 1000,
            });
          }
          this.dialogVisible = false;
          this.getGroup("", "type", "add");
        });
      } else {
        params.id = group.id;
        params.name = group.name;
        params.wid = this.workShopId;
        const token = localStorage.getItem("token");
        const lang = localStorage.getItem("language");
        editGroup(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.EditSuccess"),
              type: "success",
              duration: 1000,
            });
          }
          this.dialogVisible = false;
          this.getGroup("", "type", "edit");
        });
      }
    },
    handleAddOrder(val) {
      this.dialogVisible = true;
      this.group = {};
      this.workShopId = val.id;
      this.type = "add";
    },
    handleEditOrder(val) {
      this.dialogVisible = true;
      this.group.name = val.name;
      this.group.id = val.id;
      this.workShopId = val.id;
      this.type = "edit";
    },
    handleDeleteOrder(val) {
      this.$confirm(this.$t("Tip.DeleteGroup"), this.$t("Tip.Tips"), {
        confirmButtonText: this.$t("Login.OK"),
        cancelButtonText: this.$t("AddMachine.Cancel"),
      })
        .then(() => {
          const params = {};
          params.id = val.id;
          const token = localStorage.getItem("token");
          const lang = localStorage.getItem("language");
          deleteGroup(params, token, lang).then((response) => {
            if (response.code === 200) {
              this.$message({
                showClose: true,
                message: this.$t("Setting.RemovedSuccessfully"),
                type: "success",
                duration: 1000,
              });
            }
            this.getGroup("", "type", "delete");
          });
        })
        .catch(() => {});
    },
    searchGroup() {
      if (!this.repeatSearch) {
        return;
      }
      this.repeatSearch = false;
      setTimeout(() => {
        this.repeatSearch = true;
      }, 2000);
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      this.getGroup(this.groupName, "", "");
    },
    async getGroup(val, type, item) {
      if (!val && !type) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Criteria"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {};
      params.page = this.page;
      params.pagesize = this.pagesize;
      params.name = val;
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("lang");
      const res = await getGroup(params);
      this.tableData = res.data.list;
      // 获取所有的设备组名称

      this.total = res.data.count;
      this.changeLan();
      if (!item) {
        this.expandRowKeys = [];
      }
      if (val) {
        for (var i = 0; i < this.tableData.length; i++) {
          this.expandRowKeys.push(this.tableData[i].id);
        }
      }
      loading.close();
      return Promise.resolve(res.data.list);
    },

    querySearch(queryString, cb) {
      const filterArr = this.groupNameList.map((item) => {
        return {
          value: item.name,
          ...item,
        };
      });
      const results = queryString
        ? filterArr.filter(this.createFilter(queryString))
        : filterArr;
      cb(results);
    },
    createFilter(queryString) {
      return (data) => {
        return data.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pagesize = val;
      this.getGroup("", "type");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getGroup("", "type");
    },
    row_key(row) {
      return row.id;
    },
    expandChange(row, rowList) {
      this.expandRowKeys = [];
      rowList.forEach((item) => {
        this.expandRowKeys.push(item.id);
      });
    },
    rowClick(row, event, column) {
      Array.prototype.remove = function (val) {
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (column.target.dataset?.customName == "addGroup") return;
      if (this.expandRowKeys.indexOf(row.id) < 0) {
        this.expandRowKeys.push(row.id);
      } else {
        this.expandRowKeys.remove(row.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (max-width: 1367px) {
  .machine_search_list {
    flex: 1.85 !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1441px) {
  .machine_search_list {
    flex: 1.5 !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}
@media (min-width: 1441px) and (max-width: 1661px) {
  .machine_search_list {
    flex: 1.7 !important;
  }
}
@media (min-width: 1661px) and (max-width: 1919px) {
  .machine_search_list {
    flex: 1.6 !important;
  }
}
.pagination-container {
  margin: 20px 25px;
  float: left;
}
.clear {
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
  font-weight: bold;
}
.dialog /deep/ .el-form-item__content {
  line-height: 35px;
  font-size: 18px;
}
.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  margin-top: 30vh !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}
.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}
.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}
.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}
.dialog /deep/ .el-dialog__body {
  padding: 20px 20px;
}
.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}
.dialog /deep/ .el-input__suffix {
  right: 20px;
}
.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;
    .table_content {
      height: calc(100vh - 270px);
      overflow: auto;
      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;

        background-color: #ebecee;
      }
      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
    }
    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 10px 25px;
      .machine_search_list {
        flex: 1.35;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }
        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;

          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }
          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }
          /deep/ .el-input__suffix-inner .el-input__icon {
            margin-top: -2px;
          }
          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }
          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }
      .machine_search_btn {
        flex: 0.3;
        .search_btn {
          width: 70px;
          height: 31px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 31px;
          border-radius: 30px;
          margin-top: 12px;
          margin-right: 10px;
          cursor: pointer;
        }
        .edi_color {
          background-color: #767e95;
        }
      }
      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
