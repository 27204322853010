<template>
<div class="main">
  <div class="app-wrapper">
    <div class="machine_title">{{$t('Machine.Setting')}}</div>
    <div class="moldMain">
      <el-tabs
        v-model="activeName"
        tab-position="top"
        type="card"
      >
        <el-tab-pane :label="$t('Machine.Workshop')" name="Workshop" />
        <el-tab-pane :label="$t('Machine.Group')" name="Group"/>
        <el-tab-pane :label="$t('Machine.Equipment')" name="Machine"/>
        <el-tab-pane :label="$t('Setting.User')" name="User"/>
      </el-tabs>
      <component
        :is="componentId[activeName]"
        @forwardTab="forward"
      />
    </div>
  </div>
</div>
</template>

<script>
import Workshop from './Workshop/index'
import Group from './Group/index'
import Machine from './Machine/index'
import User from './User/index'
import '../../style/table.less'
export default {
  name: 'MoldName',
  components: {
    Workshop,
    Group,
    Machine,
    User
  },
  mixins: [],
  data () {
    return {
      activeName: 'Workshop',
      componentId: {
        Workshop: 'Workshop',
        Group: 'Group',
        Machine: 'Machine',
        User: 'User'
      }
    }
  },
  methods: {
    forward (activeName) {
      this.activeName = activeName
    }
  },
  created () {

  }
}
</script>

<style lang='less' scoped>
.main  /deep/ #tab-Workshop{
  background-image: url('../../assets/image/factory.png');
  background-size: 25px 25px;
  background-position: 3px 7px;
  background-repeat: no-repeat;
}
.main  /deep/ #tab-Group{
  background-image: url('../../assets/image/group.png');
  background-size: 24px 24px;
  background-position: 3px 8px;
  background-repeat: no-repeat;
  width: 112px !important;
  padding-left: 40px !important;
}
.main  /deep/ #tab-Machine{
  background-image: url('../../assets/image/设备 (2)@2x.png');
  background-size: 28px 28px;
  background-position: 3px 7px;
  background-repeat: no-repeat;
}
.main  /deep/ #tab-User{
  background-image: url('../../assets/image/用户@2x.png');
  background-size: 26px 26px;
  background-position: 3px 6px;
  background-repeat: no-repeat;
}
.main{
  width: 100%;
  height: 100%;
  background-color: #ebecee;
  .app-wrapper{
    background-color: #ebecee;
    height: 100%;
    .machine_title{
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .moldMain{
      background-color: #ebecee;
      /deep/ .el-tabs__header{
        margin: 0px;
        border-bottom: 2px solid rgba(145,149,158,0.45) !important;
      }
      /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
          border-bottom: 5px solid  #4478f3 !important;
          color: #4478f3;
      }
      /deep/ .el-tabs__item {
          padding: 0px;
          margin-right: 50px;
          min-width: 100px;
          padding-left: 40px;
          text-align: left;
      }
      /deep/ .el-tabs__item{
        border: none !important;
        font-weight: 700;
        font-size: 20px;
        height: 48px
      }
      /deep/ .el-tabs__nav{
        border: none !important;
        padding-left: 25px;
      }
      /deep/ .el-tabs__item:nth-child(2) {
          padding-left: 0px !important;
      }
      /deep/ .el-tabs__item:last-child {
          padding-right: 0px !important;
      }
      /deep/ .el-tabs__nav-wrap {
          margin-bottom: -3px;
      }
    }
  }
}

</style>
