<template>
<div class="main">
  <div class="app-wrapper">
    <div class="machine_search">
      <div class="machine_search_list">
        <div class="search_title">{{$t('Machine.Name')}}</div>
        <div class="search_selected">
            <el-input :label="$t('Setting.User')" v-model.trim="user" :placeholder="$t('Setting.IpUserName')" clearable/>
        </div>
      </div>
      <div class="machine_search_btn">
        <div class="search_btn" @click="searchUser" :style="{background:isBlueSearch?'#3660DD':'#4875ef'}">{{$t('Machine.Search')}}</div>
      </div>
      <div class="machine_search_btn" style="margin-left:10px">
        <div class="search_btn" @click="reset" :style="{background:isBlue?'#3660DD':'#4875ef'}">{{$t('Warning.Reset')}}</div>
      </div>
      <div class="machine_search_space">
      </div>
      <div class="machine_search_btn" @click="addUser()">
        <div class="search_btn" style="margin-right:20px;margin-top:10px">{{$t('Machine.Add')}}</div>
      </div>
    </div>
  <div class="table_content">
    <el-table
    ref="multipleTable"
    :data="tableData"
    tooltip-effect="dark"
    :header-cell-style="{background:'#dde0e8'}"
    highlight-current-row
    style="width:100%"
  >

    <!-- ele 暂无数据插槽 -->
    <!-- <template slot="empty">
      <img src="@/assets/image/emptyData.png" v-show='noDataTable'/>
      <div class="empty_text" v-show='noDataTable'>{{ $t('Basic.NoData')}}</div>
    </template> -->
    <el-table-column width="50" align="center" />
    <el-table-column min-width="20%" :label="$t('Setting.User')" show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.nickname }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="20%"  :label="$t('Login.Account')" show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.account }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="18%" :label="$t('Login.MobilePhone')" show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.mobile }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="24%"  :label="$t('Login.Email')" show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.email }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="20%"  :label="$t('Setting.Level')" show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.role }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="20%"  :label="$t('Setting.CreationTime')" show-overflow-tooltip>
      <template slot-scope="scope">
        <div>{{ scope.row.createtime |time }}</div>
      </template>
    </el-table-column>
          <el-table-column :label="$t('AddMachine.Operation')"  width="150" align="left">
            <template slot-scope="scope">
              <img src="../../../assets/image/authorization.png" @click="handleAuthOrder(scope.row)" class="OperationImg" style="margin-right:15px;width: 21px;">
              <img src="../../../assets/image/bianji_main@2x.png" @click="handleEditOrder(scope.row)" class="OperationImg" style="margin-right:15px">
              <img src="../../../assets/image/shanchu_mian@2x.png" @click="handleDeleteOrder(scope.row)" class="OperationImg" style="margin-right:15px">
            </template>
          </el-table-column>
  </el-table>
  </div>
  <div class="pagination-container">
    <el-pagination
      background
      layout="prev, pager, next, sizes"
      :page-size="pagesize"
      :page-sizes="[5,10,15]"
      :current-page.sync="page"
      :total="total"
      v-show="total&&total>0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  </div>
        <el-dialog
        :title="type=='edit'?$t('Setting.EditUser'):$t('Setting.AddUser')"
        :visible.sync="dialogVisible"
        width="600px"
        class="dialog"
        >
        <el-form ref="workShopForm" :model="userInfo" label-width="0px">
            <div class="form-box">
            <el-form-item prop="name">
                <div class="label" style="text-align:left">{{$t('UserInfo.Account')}}</div>
                <el-input v-model.trim="userInfo.name" :placeholder="$t('Setting.IptAccout')"  autocomplete="new-password"  clearable  @input="changeIpt($event)" :disabled='type==="edit"'/>
            </el-form-item>
            </div>
            <div class="form-box" v-show="type=='add'">
            <el-form-item prop="name">
                <div class="label" style="text-align:left">{{$t('Login.Password')}}</div>
                <el-input v-model.trim="userInfo.password" :placeholder="$t('Setting.IpPwd')"  autocomplete="new-password"   type="password"  clearable  @input="changeIpt($event)"/>
            </el-form-item>
            </div>
            <div class="form-box" style="display:flex;padding-right:15px">
              <el-form-item prop="nickname" style="flex:0.9">
                  <div class="label" style="text-align:left">{{$t('UserInfo.UserName')}}</div>
                  <el-input v-model.trim="userInfo.nickname" :placeholder="$t('Setting.IpUserName')" auto-complete="off"  clearable  @input="changeIpt($event)"/>
              </el-form-item>
              <el-form-item prop="name" style="flex:0.9;margin-left:20px" class="userRole">
                  <div class="label" style="text-align:left">{{$t('Setting.Role')}}</div>
                  <el-select  :placeholder="$t('Setting.IptRole')"  v-model="userInfo.account"  @change="changeIpt($event)">
                        <el-option v-for="item in userType" :label="item.name" :value="item.id" :key="item.id"></el-option>
                  </el-select>
              </el-form-item>
            </div>

            <div class="form-box" style="padding-right:15px">
            <el-form-item prop="name" class="userRole">
                <div class="label" style="text-align:left">{{$t('Machine.Workshop')}}</div>
                <el-select  :placeholder="$t('Setting.WorkshopInput')" multiple v-model="userInfo.workshop">
                      <el-option v-for="item in workshops" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            </div>
            <div class="form-box">
            <el-form-item prop="name">
                <div class="label" style="text-align:left">{{$t('Login.MobilePhone')}}</div>
                <el-input v-model.trim="userInfo.phone" :placeholder="$t('Login.phoneNumber')" auto-complete="off"  clearable  @input="changeIpt($event)"/>
            </el-form-item>
            </div>
            <div class="form-box">
            <el-form-item prop="name">
                <div class="label" style="text-align:left">{{$t('Login.Email')}}</div>
                <el-input v-model.trim="userInfo.email" :placeholder="$t('Setting.IptEmail')" auto-complete="off"  clearable  @input="changeIpt($event)"/>
            </el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">

            <div class="btn-normal pan-btn blue-btn" style="margin-right:0px;background:#4875ef" @click="handleConfirm()" :style="{background:isBlueConfire?'#3660DD':'#4875ef'}">{{$t('Login.OK')}}</div>
            <div class="btn-normal pan-btn tiffany-btn" @click="dialogVisible = false" style='background:#767e95'>{{$t('AddMachine.Cancel')}}</div>
        </span>
        </el-dialog>
        <el-dialog
        :title="$t('Setting.PowerControl')"
        :visible.sync="powerVisible"
        width="700px"
        class="dialog dialogs"
        >
        <el-form ref="workShopForm" :model="userInfo" label-width="0px">
          <div class="machine_search" style="padding: 0px 25px 10px 10px;">
            <div class="machine_search_list" style="flex:0.55">
              <div class="search_title">{{$t('Setting.Serial')}}</div>
              <div class="search_selected">
                  <el-input :label="$t('Setting.User')" v-model.trim="series" :placeholder="$t('AddMachine.inputSerialNumber')" clearable/>
              </div>
            </div>
            <div class="machine_search_btn">
              <div class="search_btn" @click="searchMachine">{{$t('Machine.Search')}}</div>
            </div>

          </div>
        </el-form>
    <div class="table_content">
        <el-table
        ref="multipleTable"
        :data="workshopData"
        tooltip-effect="dark"
        :header-cell-style="{background:'#dde0e8'}"
        highlight-current-row
        style="width:100%"

        :row-key="row_key" :expand-row-keys="expandRowKeys" @expand-change="expandChange"
    >
        <el-table-column width="10" align="center" />
    <el-table-column type="expand" min-width="10%">

        <template slot-scope="props">
        <el-table ref="multipleTable" :data="props.row.devices" tooltip-effect="dark" style="width: 100%" :header-cell-style="{background:'#dde0e8'}">
            <el-table-column width="80" align="center" />
            <el-table-column :label="$t('AddMachine.EquipmentName')" prop="name" min-width="27%"  show-overflow-tooltip/>
            <el-table-column :label="$t('AddMachine.SerireSimple')" prop="serial_number" min-width="22%"  show-overflow-tooltip/>
            <el-table-column :label="$t('Setting.OnlyCheck')" min-width="22%" align="left" class="check"  show-overflow-tooltip>
              <template slot-scope="scope">
                <el-checkbox  v-model='scope.row.check'></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Setting.Control')" min-width="25%" align="left" class="check"  show-overflow-tooltip>
              <template slot-scope="scope">
                <el-checkbox v-model='scope.row.control' @change="controlChange(scope.row,scope.row.control)"></el-checkbox>
                </template>
            </el-table-column>
        </el-table>
        </template>
    </el-table-column>
        <el-table-column min-width="20%" :label="$t('Machine.Workshop')">
        <template slot-scope="scope">
            <div>{{ scope.row.name }}</div>
        </template>
        </el-table-column>

    <el-table-column width="20" align="center" />
    </el-table>
    </div>
        <span slot="footer" class="dialog-footer">

            <div class="btn-normal pan-btn blue-btn" style="margin-right:0px;background:#4875ef" @click="handPower()">{{$t('Login.OK')}}</div>
            <div class="btn-normal pan-btn tiffany-btn" @click="powerVisible = false" style='background:#767e95'>{{$t('AddMachine.Cancel')}}</div>
        </span>
        </el-dialog>
    </div>
</template>

<script>

import { getUser, deleteUser, addUser, editUser, roleList, listSearch, permissionList, permissionChange } from '@/api/machine'
export default {
  name: 'User',
  data () {
    return {
      Startime: '',
      type: '',
      user: '',
      series: '',
      workshops: [],
      userType: [],
      workshopData: [],
      expandRowKeys: [],
      isBlue: false,
      isBlueSearch: false,
      isBlueConfire: false,
      repeat: true,
      repeatSearch: true,
      repeatPower: true,
      repeatPowerSearch: true,
      userInfo: {
        name: '',
        password: '',
        id: '',
        phone: '',
        nickname: '',
        email: '',
        account: '',
        workshop: []
      },
      currentWid: '',
      dialogVisible: false,
      powerVisible: false,
      workShop: '',
      Endtime: '',
      tableData: [],
      total: 0,
      page: 1,
      pagesize: 15
    }
  },
  filters: {
    time (item) {
      const date = new Date(item * 1000)
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
      const hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
      const minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
      return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute
    }
  },
  created () {
    this.roleList()
    this.getUser('', 'type')
    this.listSearch()
  },
  watch: {
    powerVisible (value) {
      if (!value) {
        this.currentWid = ''
      }
    },
    '$i18n.locale' (val) {
      this.changeLan()
      this.changeWorkshopLan()
      this.changeRoleLan()
    }
  },
  methods: {
    changeRoleLan () {
      if (this.userType.length > 0) {
        for (var i = 0; i < this.userType.length; i++) {
          if (this.userType[i].name === '管理员' || this.userType[i].name === 'Administrators') {
            this.$set(this.userType[i], 'name', this.$t('UserInfo.Administrators'))
          } else if (this.userType[i].name === '用户' || this.userType[i].name === 'User') {
            this.$set(this.userType[i], 'name', this.$t('UserInfo.Users'))
          }
        }
      }
    },
    changeWorkshopLan () {
      if (this.workshops.length > 0) {
        if (this.workshops[0].name === '默认车间' || this.workshops[0].name === 'Default Workshop') {
          this.$set(this.workshops[0], 'name', this.$t('Tip.Default'))
        }
      }
    },
    changeLan () {
      if (this.workshopData.length > 0) {
        if (this.workshopData[0].name === '默认车间' || this.workshopData[0].name === 'Default Workshop') {
          this.$set(this.workshopData[0], 'name', this.$t('Tip.Default'))
        }
      }
    },
    checkEmail (val) {
      const t = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/
      if (!(t.test(val))) {
        return false
      } else {
        return true
      }
    },
    controlChange (row, val) {
      if (val) {
        for (var i = 0; i < this.workshopData.length; i++) {
          for (var j = 0; j < this.workshopData[i].devices.length; j++) {
            if (parseInt(this.workshopData[i].devices[j].id) === parseInt(row.id)) {
              this.$set(this.workshopData[i].devices[j], 'check', true)
            }
          }
        }
      }
    },
    searchMachine () {
      if (!this.repeatPowerSearch) {
        return
      }
      this.repeatPowerSearch = false
      setTimeout(() => {
        this.repeatPowerSearch = true
      }, 2000)
      if (!this.series) {
        this.$message({
          showClose: true,
          message: this.$t('Tip.Criteria'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.permissionList('Search')
    },
    changeIpt (e) {
      this.$forceUpdate()
    },
    resetData () {
      this.userInfo.name = ''
      this.userInfo.password = ''
      this.userInfo.id = ''
      this.userInfo.phone = ''
      this.userInfo.nickname = ''
      this.userInfo.email = ''
      this.userInfo.account = ''
      this.userInfo.workshop = []
    },
    reset () {
      this.isBlue = true
      setTimeout(() => {
        this.isBlue = false
      }, 500)
      this.user = ''
      this.getUser('', 'type')
    },
    handPower () {
      if (!this.repeatPower) {
        return
      }
      this.repeatPower = false
      setTimeout(() => {
        this.repeatPower = true
      }, 2000)
      const params = {}
      params.admin_id = this.currentWid
      const data = []

      for (var i = 0; i < this.workshopData.length; i++) {
        for (var j = 0; j < this.workshopData[i].devices.length; j++) {
          const obj = {
            device_id: '',
            refuse_level: ''
          }
          if (this.workshopData[i].devices[j].check && this.workshopData[i].devices[j].control) {
            obj.device_id = parseInt(this.workshopData[i].devices[j].id)
            obj.refuse_level = 0
          } else if (!this.workshopData[i].devices[j].check && this.workshopData[i].devices[j].control) {
            obj.device_id = parseInt(this.workshopData[i].devices[j].id)
            obj.refuse_level = 0
          } else if (this.workshopData[i].devices[j].check && !this.workshopData[i].devices[j].control) {
            obj.device_id = parseInt(this.workshopData[i].devices[j].id)
            obj.refuse_level = 1
          } else if (!this.workshopData[i].devices[j].check && !this.workshopData[i].devices[j].control) {
            obj.device_id = parseInt(this.workshopData[i].devices[j].id)
            obj.refuse_level = 2
          }
          data.push(obj)
        }
      }
      // const objs = {}
      // // 1.使用for in 遍历
      // for (const key in data) { // 这里key索引
      //   objs[key] = data[key]
      // }
      params.data = data
      const token = localStorage.getItem('token')
      const lang = localStorage.getItem('language')
      permissionChange(params, token, lang).then((response) => {
        if (response.code === 200) {
          this.powerVisible = false
          this.$message({
            showClose: true,
            message: this.$t('Tip.EditSuccess'),
            type: 'success',
            duration: 1000
          })
        }
      })
    },
    permissionList (val) {
      const params = {}
      params.admin_id = this.currentWid
      params.serial_number = this.series
      const token = localStorage.getItem('token')
      const lang = localStorage.getItem('language')
      permissionList(params, token, lang).then((res) => {
        this.workshopData = res.data.list
        this.changeLan()
        for (var i = 0; i < this.workshopData.length; i++) {
          for (var j = 0; j < this.workshopData[i].devices.length; j++) {
            if (this.workshopData[i].devices[j].permission === 0) {
              this.$set(this.workshopData[i].devices[j], 'check', true)
              this.$set(this.workshopData[i].devices[j], 'control', true)
            } else if (this.workshopData[i].devices[j].permission === 1) {
              this.$set(this.workshopData[i].devices[j], 'check', true)
              this.$set(this.workshopData[i].devices[j], 'control', false)
            } else if (this.workshopData[i].devices[j].permission === 2) {
              this.$set(this.workshopData[i].devices[j], 'check', false)
              this.$set(this.workshopData[i].devices[j], 'control', false)
            }
          }
        }
        if (val) {
          for (var l = 0; l < this.workshopData.length; l++) {
            this.expandRowKeys.push(this.workshopData[l].id)
          }
        }
      })
    },
    listSearch () {
      const params = {}
      params.query_level = 0
      const token = localStorage.getItem('token')
      const lang = localStorage.getItem('language')
      listSearch(params, token, lang).then((res) => {
        this.workshops = res.data
        this.changeWorkshopLan()
      })
    },
    roleList () {
      const params = {}
      params.token = localStorage.getItem('token')
      params.language = localStorage.getItem('language')
      roleList(params).then((res) => {
        this.userType = res.data
        this.changeRoleLan()
      })
    },
    handleAuthOrder (row) {
      this.powerVisible = true
      this.workshopData = []
      this.series = ''
      this.currentWid = row.id
      this.permissionList()
    },
    deleteNaN (arr) {
      var res = []
      for (var i = 0; i < arr.length; i++) {
        // eslint-disable-next-line no-self-compare
        if (arr[i] === arr[i]) {
          res.push(arr[i])
        }
      }
      return res
    },
    handleEditOrder (row) {
      this.dialogVisible = true
      this.type = 'edit'
      this.userInfo.id = row.id
      this.userInfo.account = row.rid
      this.userInfo.nickname = row.nickname
      this.userInfo.name = row.account
      this.userInfo.phone = row.mobile
      this.userInfo.email = row.email
      this.userInfo.workshop = this.deleteNaN(row.wids.split(','))
      this.userInfo.workshop = this.userInfo.workshop.map(Number)
    },
    handleDeleteOrder (val) {
      this.$confirm(this.$t('Tip.DeleteUser'), this.$t('Tip.Tips'), {
        confirmButtonText: this.$t('Login.OK'),
        cancelButtonText: this.$t('AddMachine.Cancel')
      }).then(() => {
        const params = {}
        params.id = val.id
        const token = localStorage.getItem('token')
        const lang = localStorage.getItem('language')
        deleteUser(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t('Setting.RemovedSuccessfully'),
              type: 'success',
              duration: 1000
            })
          }
          this.getUser('', 'type')
        })
      }).catch(() => {
      })
    },
    searchUser () {
      if (!this.repeatSearch) {
        return
      }
      this.repeatSearch = false
      setTimeout(() => {
        this.repeatSearch = true
      }, 2000)
      this.isBlueSearch = true
      setTimeout(() => {
        this.isBlueSearch = false
      }, 500)
      this.getUser(this.user)
    },
    addUser () {
      this.dialogVisible = true
      this.type = 'add'
      this.resetData()
    },
    handleConfirm () {
      this.isBlueConfire = true
      setTimeout(() => {
        this.isBlueConfire = false
      }, 500)
      const params = {}
      if (!this.repeat) {
        return
      }
      this.repeat = false
      setTimeout(() => {
        this.repeat = true
      }, 2000)
      if (!this.userInfo.name) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.IptAccout'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.userInfo.name.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t('Tip.AccountExceed'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.type === 'add' && !this.userInfo.password) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.IpPwd'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.type === 'add' && this.userInfo.password && this.userInfo.password.length < 6) {
        this.$message({
          showClose: true,
          message: this.$t('Tip.Digits'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (!this.userInfo.nickname) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.IpUserName'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.userInfo.nickname.length >= 20) {
        this.$message({
          showClose: true,
          message: this.$t('Tip.UserExceed'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (!this.userInfo.account) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.IptRole'),
          type: 'warning',
          duration: 1000
        })
        return
      }

      if (this.userInfo.workshop.length === 0) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.WorkshopInput'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (!this.userInfo.phone) {
        this.$message({
          showClose: true,
          message: this.$t('Login.phoneNumber'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (!this.userInfo.email) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.IptEmail'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (!this.checkEmail(this.userInfo.email)) {
        this.$message({
          showClose: true,
          message: this.$t('Setting.ErrEmail'),
          type: 'warning',
          duration: 1000
        })
        return
      }

      if (this.type === 'add') {
        params.password = this.$md5(this.userInfo.password)
        params.wids = this.userInfo.workshop.join(',')
        params.rid = this.userInfo.account
        params.nickname = this.userInfo.nickname
        params.account = this.userInfo.name
        params.mobile = this.userInfo.phone
        params.email = this.userInfo.email
        const token = localStorage.getItem('token')
        const lang = localStorage.getItem('language')
        addUser(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.dialogVisible = false
            this.$message({
              showClose: true,
              message: this.$t('Tip.AddSuccess'),
              type: 'success',
              duration: 1000
            })
            this.getUser('', 'type')
          }
        })
      } else {
        params.id = this.userInfo.id
        params.wids = this.userInfo.workshop.join(',')
        params.rid = this.userInfo.account
        params.nickname = this.userInfo.nickname
        params.account = this.userInfo.name
        params.mobile = this.userInfo.phone
        params.email = this.userInfo.email
        const token = localStorage.getItem('token')
        const lang = localStorage.getItem('language')
        editUser(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.dialogVisible = false
            this.$message({
              showClose: true,
              message: this.$t('Tip.EditSuccess'),
              type: 'success',
              duration: 1000
            })
            this.getUser('', 'type')
          }
        })
      }
    },
    getUser (val, type) {
      if (!val && !type) {
        this.$message({
          showClose: true,
          message: this.$t('Tip.Criteria'),
          type: 'warning',
          duration: 1000
        })
        return
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t('Tip.Loading'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = {}
      params.page = this.page
      params.pagesize = this.pagesize
      params.nickname = val
      params.token = localStorage.getItem('token')
      params.language = localStorage.getItem('language')
      getUser(params).then((res) => {
        this.tableData = res.data.list.filter(item => item.rid !== 3)
        this.total = res.data.count
      })
      loading.close()
    },
    handleSizeChange (val) {
      this.page = 1
      this.pagesize = val
      this.getUser('', 'type')
    },
    handleCurrentChange (val) {
      this.page = val
      this.getUser('', 'type')
    },
    row_key (row) {
      return row.id
    },
    expandChange (row, rowList) {
      this.expandRowKeys = []
      rowList.forEach(item => {
        this.expandRowKeys.push(item.id)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@media (max-width:1367px) {
  .machine_search_list{
    flex: 1.9 !important;
  }
  .dialog /deep/ .el-input__suffix {
      right: 20px !important;
  }
.dialogs /deep/ .el-dialog {
    margin-top: 12vh !important;
}
}
@media (min-width:1367px) and (max-width:1441px) {
  .machine_search_list{
    flex: 1.55 !important;
  }
  .dialog /deep/ .el-input__suffix {
      right: 20px !important;
  }
.dialog  /deep/ .el-dialog {
    margin-top: 10vh !important;
}
}
@media (min-width:1441px) and (max-width:1601px) {
.dialog  /deep/ .el-dialog {
    margin-top: 10vh !important;
}
  .machine_search_list{
    flex: 1.75 !important;
  }
}
@media (min-width:1601px) and (max-width:1919px) {
.dialog  /deep/ .el-dialog {
    margin-top: 10vh !important;
}
  .machine_search_list{
    flex: 1.6 !important;
  }
}
.pagination-container {
    margin: 20px 25px;
    float: left;
}

.app-wrapper /deep/.el-table__empty-text {
    line-height: 20px !important;
    font-weight: bold;
    font-size: 16px;
    color: #909399;
}
.dialog /deep/ .el-dialog{
    border-radius: 20px !important;
    background: #e7e9ee;
    filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialog /deep/ .el-checkbox{
  margin-left: 15px;
}
.dialogs /deep/ .table_content .el-table__expanded-cell .el-table th {
    padding: 5px 0 !important;
}
 .dialogs .table_content{
    height:calc(100vh - 470px) !important;
 }
.dialogs .table_content /deep/ .has-gutter > tr > th {
    font-size: 16px !important;
}
.dialogs .table_content /deep/ .el-table__expanded-cell .el-table td {
    font-size: 14px !important;
}
.dialog /deep/ .el-form-item__content {
    line-height: 35px;
    font-size: 18px;
  }
.dialog /deep/ .el-dialog__body {
    padding: 0px 20px 10px 20px;
}
.dialog /deep/ .el-input__suffix {
    right:20px;
}
.dialog /deep/ .el-input__suffix {
  top:2px
}
.dialog .userRole /deep/ .el-input__suffix {
    right:5px !important;
}

.btn-normal {
    width: 70px;
    height: 32px;
    border-radius: 20px;
    font-size: 13px;
    line-height: 34px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    float: right;
    margin-left: 10px;
    cursor: pointer;
}
.dialog /deep/ .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
}
.dialog /deep/  .el-select__tags{
      height: auto !important;
      flex-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
}
.dialog /deep/ .el-select {
  width: 100%;
}

.dialog /deep/ .el-select  .el-input--suffix {
    width: 100% !important;
    padding-right: 0px;
}
.dialog /deep/ .plan-form.r3 .label {
    font-size: 16px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}
.dialog /deep/ .plan-form .row .form-item{
    width: 33.4% !important;
}
.dialog /deep/ .el-input__icon {
    line-height: 35px;
}
.dialog /deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 20px;
}
.dialog /deep/ .el-dialog__body{
  padding: 20px 20px !important;
}
.dialog /deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn{
  font-size: 30px !important;
  top: 12px;
}
.dialog /deep/ .el-dialog__footer {
    padding: 0px 20px 15px !important;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}
.main{
  width: 100%;
  height: 100%;
  background-color: #ebecee;

.app-wrapper{
  background-color: #ebecee;
  height: 100%;
  .table_content{
    height:calc(100vh - 270px);
    overflow: auto;
    /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #ebecee;
    }
    /deep/ .el-table tr {
      // background-color: #ebecee !important;
      cursor: pointer;
    }
  }
  .machine_title{
    padding: 22px 20px;
    color: #414141;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
  }
  .machine_search{
    display: flex;
    flex-direction: row;
    padding: 10px 25px;
    .machine_search_list{
      flex: 1.3;
      .search_title{
        float: left;
        text-align: left;
        height: 32px;
        line-height: 32px;
        margin: 10px 10px 10px 0;
        color:#4d4f5c;
        font-size: 18px;
      }
      .search_selected{
        float: left;
        margin: 10px 10px 10px 0;

        /deep/ .el-date-editor.el-input,/deep/ .el-date-editor.el-input__inner {
            width: 100%;
        }
        /deep/ .el-input__inner{
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input__icon {
          margin-top: 5px;
          color:#a4afb7;
          font-weight:bold;
        }
        /deep/ .el-input__suffix-inner .el-input__icon{
          margin-top: -2px;
        }
        /deep/ .input-wrapper .el-input__icon{
          margin-top: 2px;
        }
        /deep/ .el-select .el-input .el-select__caret.is-reverse {
          margin-top: -3px;
        }
      }
    }
    .machine_search_btn{
      .search_btn{
        width: 70px;
        height: 32px;
        background: #4875ef;
        font-size: 13px;
        color:#f2f3f6;
        text-align: center;
        line-height: 32px;
        border-radius:30px;
        margin-top: 10px;
        margin-right: 10px;
        cursor: pointer;
      }
      .edi_color{
        background-color: #767e95;
      }
    }
    .machine_search_space{
      flex: 5.4;
    }
  }
}
}
.dialogs /deep/ .el-dialog__body {
    padding: 0px 20px 20px 20px !important;
}
</style>
